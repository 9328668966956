import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby'
import PrimaryButton from "../../components/Buttons/PrimaryButton"


// markup
const CareersPage = () => {
  const query = useStaticQuery(graphql`
  query MyQuery {
    jobListings {
      result
    }
  }  
`)

const jobResult = JSON.parse(query.jobListings.result)

// Dummy content for design review
if(!jobResult.jobs.length){
  jobResult.jobs = [
    {
      title: 'Sr. Product Marketing Manager',
      absolute_url: '#',
      location: {
        name: "Remote"
      }
  
    },
    {
      title: 'Administrator',
      absolute_url: '#',
      location: {
        name: "Remote"
      }
  
    },
    {
      title: 'Production Assistant',
      absolute_url: '#',
      location: {
        name: "Remote"
      }
  
    },
    {
      title: 'Graphic Designer',
      absolute_url: '#',
      location: {
        name: "Remote"
      }
  
    },
    {
      title: 'Specialist, Social Media',
      absolute_url: '#',
      location: {
        name: "Remote"
      }
  
    },
    {
      title: 'Sr. Manager, PR & Comms',
      absolute_url: '#',
      location: {
        name: "Remote"
      }
  
    }
  ]
}

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blokhaus - Careers</title>
      </Helmet>
      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block w-full pt-10 pb-6 px-6 md:pt-24 md:pb-16 mx-auto text-left">
          <div className={`w-full mx-auto mb-10 md:mb-0`}>
            <h1 className={`h2 text-left mb-8 md:mb-0`}>Blokhaus is growing. Explore open roles,<br className={'hidden md:block'} />or get in touch. We'd love to hear from you.</h1>
          </div>
        </div>
      </div>

      <div className={`text-black md:pb-8`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative sm:grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-5 pt-0 px-6 mx-auto mb-0 md:mb-2 text-left">
          {jobResult.jobs.map((job, index) => {
            return (
              <div className={'md:mb-9'} key={index}>
                <h3 className={'p'}>
                  <a href={job.absolute_url} target={'_blank'} className={'underline font-bold'}>
                    {job.title}
                  </a><br />
                  Location: {job.location.name}<br /><br />
                </h3>
              </div>
            )
          })}
        </div>
        {!jobResult.jobs.length && 
          <div style={{ maxWidth: 1600 }} className="z-10 relative pt-0 px-6 mx-auto mb-0 md:mb-2 text-left">
              <div className={'md:mb-9'}>
                <h3 className={'p'}>We currently don't have any openings, but we're always on the look for fresh talent.</h3>
              </div>
            </div>
          }
          <div style={{ maxWidth: 1600 }} className="z-10 relative pt-0 px-6 mx-auto mb-0 md:mb-2 text-left">
            <PrimaryButton
                text={`Get in touch`}
                url={`/contact`}
                className={`mb-9`}
              />
          </div>
      </div>
    </>
  )
}

export default CareersPage
